<template>
  <v-btn
    class="send-button"
    :color="buttonColor"
    type="submit"
    fab
  >
    <img
      src="@/assets/img/send.svg"
      alt="Enviar post"
    >
  </v-btn>
</template>

<script>
export default {
  name: 'SendPostBtnVue',
  props: {
    buttonColor: {
      type: String,
      required: true,
    },
  },
  methods: {
    submitPost() {
      this.$emit('submitPost');
    },
  },
};
</script>

<style lang="scss" scoped>
  .send-button {
    width: 48px;
    height: 48px;
    border-radius: 4px;
  }

  @media screen and (max-width: $md) {
    .send-button {
      width: 40px;
      height: 40px;
    }
  }
</style>
